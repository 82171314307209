
import { Component, Prop, VModel } from 'vue-property-decorator';
import { isString } from 'lodash';
import PwrVue from '@/components/PwrVue';
import i18n from '@/plugins/i18n';
import PwrCard from '@/components/Pwr/PwrCard/PwrCard.vue';
import PwrCardTitle from '@/components/Pwr/PwrCard/PwrCardTitle.vue';
import PwrBtn from '@/components/Pwr/Buttons/PwrBtn/PwrBtn.vue';

@Component({
  components: { PwrCardTitle, PwrCard, PwrBtn }
})
export default class PwrYesNoDialog extends PwrVue {
  @VModel() visible!: boolean;

  @Prop() title!: string;
  @Prop({ default: () => 500 }) maxWidth!: number;
  @Prop() persistent!: boolean | string;
  @Prop({ default: () => i18n.t('components.dialogs.yesNo.buttons.yes') }) yesButtonText!: string;
  @Prop({ default: () => i18n.t('components.dialogs.yesNo.buttons.no') }) noButtonText!: string;
  @Prop() message!: string | string[];
  @Prop() loading!: boolean | string;
  @Prop({ default: () => 'black' }) loadingColor!: string;

  @Prop() disableYseButton!: boolean | string;
  @Prop() disableNoButton!: boolean | string;

  private closeDialog(emit: string): void {
    this.visible = false;
    this.$emit(emit);
  }

  get isMessageString(): boolean {
    return isString(this.message);
  }
}
